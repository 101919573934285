<template>
    <div class="container py-3 px-3 py-md-5 px-md-5">
        <page-header header="Schools and Programs" :subText="header" />

        <!-- Modal -->
        <div
            class="modal fade"
            id="uploadModal"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabindex="-1"
            aria-labelledby="uploadModalLabel"
            aria-hidden="true"
        >
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="uploadModalLabel">
                            Upload Document
                        </h5>
                        <button
                            type="button"
                            class="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>
                    <Form
                        @submit="UploadFile"
                        :validation-schema="documentInfoschema"
                    >
                        <div class="modal-body mb-4">
                            <div class="">
                                <div class="">
                                    <label>Short Description</label>
                                    <Field
                                        v-model="documentInfo.desc"
                                        name="desc"
                                        class="form-control"
                                        type="text"
                                    />
                                    <ErrorMessage
                                        name="desc"
                                        as="div"
                                        class="text-danger"
                                    />
                                </div>
                            </div>
                            <div class="">
                                <div class="mt-3">
                                    <label>Upload document</label>
                                    <Field
                                        v-model="documentInfo.uploadFiles"
                                        id="uploadDocument"
                                        name="uploadFiles"
                                        class="form-control"
                                        type="file"
                                        required
                                    />
                                    <ErrorMessage
                                        name="uploadFiles"
                                        as="div"
                                        class="text-danger"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button
                                type="button"
                                class="btn btn-secondary"
                                data-bs-dismiss="modal"
                                id="closeUploadModal"
                            >
                                Close
                            </button>

                            <button
                                type="submit"
                                class="btn btn-primary"
                                :disabled="disableUpload"
                            >
                                Upload File
                            </button>
                        </div>
                    </Form>
                </div>
            </div>
        </div>

        <!-- Submit additional documents modal -->
        <div
            class="modal fade"
            id="uploadAdditionalModal"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabindex="-1"
            aria-labelledby="uploadAdditionalModalLabel"
            aria-hidden="true"
        >
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="uploadAdditionalModalLabel">
                            Upload Document
                        </h5>
                        <button
                            type="button"
                            class="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>
                    <Form
                        @submit="UploadAdditionalFile"
                        :validation-schema="documentInfoschema"
                    >
                        <div class="modal-body mb-4">
                            <div class="">
                                <div class="">
                                    <label>Short Description</label>
                                    <Field
                                        v-model="documentInfo.desc"
                                        name="desc"
                                        class="form-control"
                                        type="text"
                                    />
                                    <ErrorMessage
                                        name="desc"
                                        as="div"
                                        class="text-danger"
                                    />
                                </div>
                            </div>
                            <div class="">
                                <div class="mt-3">
                                    <label>Upload document</label>
                                    <Field
                                        v-model="documentInfo.uploadFiles"
                                        id="uploadDocument"
                                        name="uploadFiles"
                                        class="form-control"
                                        type="file"
                                        required
                                    />
                                    <ErrorMessage
                                        name="uploadFiles"
                                        as="div"
                                        class="text-danger"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button
                                type="button"
                                class="btn btn-secondary"
                                data-bs-dismiss="modal"
                                id="closeUploadAdditionalModal"
                            >
                                Close
                            </button>

                            <button type="submit" class="btn btn-primary">
                                Upload Additional File
                            </button>
                        </div>
                    </Form>
                </div>
            </div>
        </div>

        <form @submit.prevent="submitApplyForm">
            <div class="row">
                <div class="col-12 col-md-5">
                    <div class="mb-3 row">
                        <div class="col-12 col-md-6">
                            <div class="smaller fw-bold">Application ID</div>
                            <div class="text-gray small">
                                {{ application_id }}
                            </div>
                        </div>
                        <div class="col-12 col-md-6" v-if="application">
                            <span
                                class="badge rounded-pill bg-success text-dark py-2 px-3 text-white"
                                v-if="
                                    application.is_submitted &&
                                    application.payment &&
                                    application.payment?.amount_to_pay <=
                                        application.payment?.amount_paid
                                "
                                >Paid</span
                            >
                            <span
                                class="badge rounded-pill bg-warning text-dark py-2 px-3"
                                v-if="
                                    application.is_submitted &&
                                    application.payment &&
                                    application.payment?.amount_to_pay >
                                        application.payment?.amount_paid
                                "
                                >Pending payment</span
                            >
                        </div>
                        <div class="mt-3 col-12 col-md-6" v-if="isPaid">
                            <div class="smaller fw-bold">
                                Application Tracker
                            </div>
                            <app-tracker
                                :currentStepPass="calcTracker(application)"
                            ></app-tracker>
                        </div>
                    </div>
                    <div class="mb-3">
                        <div class="text-muted">
                            Academic session
                            <span class="text-danger ml-2 fw-bold">*</span>
                        </div>
                        <select
                            class="form-select"
                            v-model="selected_session"
                            @change="change_session"
                            required
                            :disabled="application && application.is_submitted"
                        >
                            <option value="">Select One</option>
                            <option
                                v-for="sess in program?.available_sessions"
                                :key="sess._id"
                                :value="sess.name"
                                :disabled="
                                    checkCanApply(
                                        sess.application_deadline,
                                        program?.available_sessions.length
                                    )
                                "
                            >
                                {{ sess.name }}
                                {{
                                    checkCanApply(
                                        sess.application_deadline,
                                        program?.available_sessions.length
                                    )
                                        ? ' (Deadline Passed)'
                                        : ''
                                }}
                            </option>
                        </select>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <div class="mb-3">
                                <div class="text-muted small">
                                    Resumption Date
                                </div>
                                <div>{{ resumption_date_text }}</div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="mb-3">
                                <div class="text-muted small">
                                    Application Deadline
                                </div>
                                <div>{{ application_deadline_text }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-5 mt-4 mt-md-0">
                    <h5>Student Record</h5>
                    <div class="mb-3">
                        <div class="smaller fw-bold">NAME</div>
                        <div class="text-gray">
                            {{ user?.personal_info?.first_name }}
                            {{ user?.personal_info?.last_name }}
                        </div>
                    </div>
                    <div class="mb-3">
                        <div class="smaller fw-bold">EMAIL</div>
                        <div class="text-gray">
                            {{ user?.email }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-5">
                <div class="col-12 col-md-4" v-if="program">
                    <div
                        class="d-flex mb-4 align-items-center"
                        v-if="program.university"
                    >
                        <div>
                            <img
                                :src="program.university.logo"
                                class="rounded-circle"
                                width="45"
                                height="45"
                            />
                        </div>
                        <div class="ms-3">
                            <h6 class="mb-0">
                                <router-link
                                    class="text-decoration-none primaryColor me-2"
                                    target="_blank"
                                    :to="{
                                        name: 'SchoolPage',
                                        params: { id: program.university._id },
                                        query: {
                                            name: program.university.name,
                                        },
                                    }"
                                    >{{ program.university.name }}</router-link
                                >
                            </h6>
                            <div class="small">
                                {{ program.university.address }}
                            </div>
                        </div>
                    </div>
                    <div class="mb-3">
                        <div class="text-muted small">Program Name</div>
                        <div class="fw-bolds">
                            <router-link
                                class="text-decoration-none primaryColor me-2"
                                target="_blank"
                                :to="{
                                    name: 'ProgramPage',
                                    params: {
                                        school_id: program.university._id,
                                        id: program._id,
                                    },
                                    query: {
                                        name:
                                            program.name +
                                            ' - ' +
                                            program.university.name,
                                    },
                                }"
                                >{{ program.name }}</router-link
                            >
                        </div>
                    </div>
                    <div class="mb-3">
                        <div class="text-muted small">Program Type</div>
                        <div>{{ program.program_type }}</div>
                    </div>
                    <div class="mb-3 row">
                        <div class="col-12 col-md-6">
                            <div class="text-muted small">Application Fee</div>
                            <div>
                                {{ program.currency_symbol
                                }}{{ toCurrency(markUpApplicationFee(program.application_fee)) }}
                            </div>
                        </div>
                        <div class="col-12 col-md-6">
                            <div class="text-muted small">Tuition Fee</div>
                            <div>
                                {{ program.currency_symbol
                                }}{{ toCurrency(program.tuition_fee) }}
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    class="col-12 col-md-5 mt-4 mt-md-0"
                    v-if="program && program.required_docs?.length > 0"
                >
                    <div><h5 class="mb-1">Application Requirements</h5></div>
                    <div class="small mb-4">
                        You can upload all your documents via your profile
                        <router-link
                            class="text-decoration-none primaryColor"
                            :to="{ name: 'DocumentList' }"
                            >here</router-link
                        >
                    </div>
                    <div
                        class="mb-4"
                        v-for="(req, ind) in program.required_docs"
                        :key="req._id"
                    >
                        <div class="small">
                            {{ req.name }}
                            <span
                                v-if="req.isRequired"
                                class="text-danger ml-2 fw-bold"
                                >*</span
                            >
                        </div>
                        <div class="smaller">{{ req.desc }}</div>
                        <div v-if="req.filePath && req.filePath != ''">
                            <a :href="req.filePath" target="_blank" class="small"
                            v-if="
                                application_id == '0' ||
                                (application && !application.is_submitted)
                            "
                            >Click to download this form, fill and upload it below</a>
                        </div>
                        <select
                            class="form-select mt-2 req_docs"
                            :required="req.isRequired"
                            :data-id="req._id"
                            :disabled="application && application.is_submitted"
                            :id="'req-' + ind"
                        >
                            <option value="">Select One</option>
                            <option
                                v-for="doc in docs"
                                :key="doc._id"
                                :value="doc._id"
                            >
                                {{ doc.name }} - {{ doc.desc }}
                            </option>
                        </select>
                        <div
                            class="mt-2 text-center"
                            v-if="
                                application_id == '0' ||
                                (application && !application.is_submitted)
                            "
                        >
                            <a
                                role="button"
                                class="text-danger text-decoration-none"
                                data-bs-toggle="modal"
                                data-bs-target="#uploadModal"
                                @click.prevent="saveSelectID('req-' + ind)"
                                >Or Upload New File</a
                            >
                        </div>
                    </div>
                    <!-- Emergency contact -->
                    <div><h5 class="mb-1">Emergency Contact</h5></div>
                    <div class="mb-4">
                        <div class="mb-2">
                            <label class="text-muted small" for="emergency-name"
                                >Name
                                <span class="text-danger ml-2 fw-bold"
                                    >*</span
                                ></label
                            >
                            <input
                                type="text"
                                name="emergency-name"
                                class="form-control"
                                v-model="emergency_contact_name"
                                :disabled="
                                    application && application.is_submitted
                                "
                                required
                            />
                        </div>
                        <div class="mb-2">
                            <label class="text-muted small" for="emergency-details-relationship"
                                >Relationship to you
                                <span class="text-danger ml-2 fw-bold"
                                    >*</span
                                ></label
                            >
                            <input
                                type="text"
                                name="emergency-details-relationship"
                                class="form-control"
                                v-model="emergency_contact_relationship"
                                :disabled="
                                    application && application.is_submitted
                                "
                                required
                            />
                        </div>
                        <div class="mb-2">
                            <label class="text-muted small" for="emergency-name"
                                >Email
                                <span class="text-danger ml-2 fw-bold"
                                    >*</span
                                ></label
                            >
                            <input
                                type="email"
                                name="emergency-name"
                                class="form-control"
                                v-model="emergency_contact_email"
                                :disabled="
                                    application && application.is_submitted
                                "
                                required
                            />
                        </div>
                        <div class="mb-2">
                            <label
                                class="text-muted small"
                                for="emergency-phone"
                                >Phone Number
                                <span class="text-danger ml-2 fw-bold"
                                    >*</span
                                ></label
                            >

                            <input
                                type="tel"
                                name="emergency-phone"
                                class="form-control"
                                v-model="emergency_contact_phone_number"
                                :disabled="
                                    application && application.is_submitted
                                "
                                required
                            />
                        </div>
                        <div>
                            <label
                                class="text-muted small"
                                for="emergeny-address"
                                >Address
                                <span class="text-danger ml-2 fw-bold"
                                    >*</span
                                ></label
                            >
                            <input
                                type="text"
                                class="form-control"
                                name="emergency-address"
                                v-model="emergency_contact_address"
                                :disabled="
                                    application && application.is_submitted
                                "
                                required
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-3">
                <div
                    v-if="
                        application_id == '0' ||
                        (application && !application.is_submitted)
                    "
                >
                    <button
                        type="button"
                        class="btn btn-link text-decoration-none primaryColor me-3 py-2 fw-bold mb-3 mb-md-0 px-5 px-md-2"
                        @click="saveApplication"
                    >
                        Save Application
                    </button>
                    <button type="submit" class="btn primaryBtn px-5 py-2">
                        Submit Application
                    </button>
                </div>

                <!--<button
                    type="button"
                    class="btn primaryBtn px-5 py-2"
                    @click="RequestPaymentLink"
                    v-if="
                        application_id != '0' &&
                        application &&
                        application.is_submitted &&
                        application.payment?.amount_to_pay !=
                            application.payment?.amount_paid
                    "
                >
                    Pay Application Fee
                </button>-->
                <div
                v-if="
                        application_id != '0' &&
                        application &&
                        application.is_submitted &&
                        application.payment?.amount_to_pay >
                            application.payment?.amount_paid
                    "
                >
                    <div><h5 class="mb-1">Pay Application Fee</h5></div>
                    <div>
                        <button type="button" @click="RequestPaymentLinkPaystack" class="btn primaryBtn px-5 py-2 me-3">Paying from Nigeria</button>
                        <button type="button" @click="RequestPaymentLink" class="btn primaryBtn px-5 py-2 mt-3 mt-md-0">Paying from outside Nigeria</button>
                    </div>
                </div>
            </div>
            <div
                class="col-12 col-md-6 mt-4 mt-md-0 mb-3"
                v-if="application?.application_complete"
            >
                <h5 class="primaryColor fw-bold">Final Decision</h5>
                <div>
                    <div class="mb-3">
                        <div class="text-muted">Is Application Complete</div>
                        <div
                            :class="
                                application?.application_complete
                                    ? 'text-success'
                                    : 'text-danger'
                            "
                        >
                            {{
                                application?.application_complete ? 'YES' : 'NO'
                            }}
                        </div>
                    </div>
                    <div v-if="application?.declined_by_schooliply">
                        <div class="mb-3">
                            <div class="text-muted">Declined by Schooliply</div>
                            <div class="text-danger">YES</div>
                        </div>
                        <div class="mb-3">
                            <div class="text-muted">Reason for declining</div>
                            <div class="">
                                {{ application?.reason_for_decline }}
                            </div>
                        </div>
                    </div>
                    <div v-if="application?.declined_by_schooliply == false">
                        <div class="mb-3">
                            <div class="text-muted">
                                Admission Given By School
                            </div>
                            <div
                                :class="
                                    application?.admission_granted
                                        ? 'text-success'
                                        : 'text-danger'
                                "
                            >
                                {{
                                    application?.admission_granted
                                        ? 'YES'
                                        : 'NO'
                                }}
                            </div>
                        </div>
                        <div
                            class="mb-3"
                            v-if="application?.decision_documents?.length > 0"
                        >
                            <h6 class="primaryColor fw-bold">
                                Decision documents
                            </h6>
                            <div
                                v-for="doc in application?.decision_documents"
                                :key="doc._id"
                                class="mb-3 row"
                            >
                                <div class="col-9 col-md-5">
                                    <div class="fw-bold">
                                        {{ doc.file_type }}
                                    </div>
                                </div>
                                <div class="col-3 col-md-5">
                                    <a :href="doc.path_to_file" target="_blank"
                                        ><i class="bi bi-cloud-download"></i>
                                        Download</a
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Needed conditions for additional documents modal => coming very soon-->
            <div
                class="row mt-3"
                v-if="
                    application_id != '0' &&
                    application &&
                    application.is_submitted &&
                    application.payment &&
                    application.payment?.amount_to_pay <=
                        application.payment?.amount_paid
                "
            >
                <a
                    role="button"
                    class="primaryColor text-decoration-none fw-bold mb-4"
                    data-bs-toggle="modal"
                    data-bs-target="#uploadAdditionalModal"
                    >Upload Additional File</a
                >
                <div class="col-12 col-md-9 text-center small">
                    If you want to withdraw this application, please reach out
                    to our admin.
                </div>
            </div>
        </form>
    </div>
</template>
<script>
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';
import AppTracker from '@/components/common/Tracker.vue';
import PageHeader from '@/components/PageHeader.vue';
import { mapActions } from 'vuex';
import ApiService from '@/apiServices/api.service';
import UserService from '@/apiServices/students/user.service';
import {
    toCurrency,
    markUpApplicationFee,
    calcTracker,
    checkCanApply
} from '@/utils/constants';
import { nextTick } from 'vue';
export default {
    name: 'ApplyPage',
    components: {
        PageHeader,
        AppTracker,
        Form,
        Field,
        ErrorMessage,
    },
    data: function () {
        const documentInfoschema = yup.object({
            desc: yup.string().max(25).required().label('Description'),
            uploadFiles: yup.mixed().required('File is required'),
        });
        return {
            program: null,
            header: 'Schools and Programs',
            application_id: '0',
            docs: [],
            user: null,
            application_deadline_text: 'Select Academic session first',
            resumption_date_text: 'Select Academic session first',
            selected_session: '',
            application: null,
            isPaid: false,
            maxFreeApplications: 2,
            documentInfoschema,
            documentInfo: {
                desc: '',
                uploadFiles: null,
            },
            uploadSelectId: '',
            disableUpload: false,
            emergency_contact_name: '',
            emergency_contact_relationship: '',
            emergency_contact_phone_number: '',
            emergency_contact_address: '',
            emergency_contact_email: '',
        };
    },
    mounted() {
        // this.school = Schools.find(x => x._id === Number(this.$route.params.id))
        this.application_id = this.$route.params.id;
        if (this.application_id === '0') {
            this.startNewApp();
        } else {
            this.resumeApp();
        }
    },
    methods: {
        ...mapActions({
            updateModal: 'general/updateInfo',
            getAllDocuments: 'user/getAllDocuments',
        }),
        toCurrency,
        markUpApplicationFee,
        checkCanApply,
        saveSelectID(val) {
            this.uploadSelectId = val;
        },
        async UploadFile() {
            this.disableUpload = true;
            UserService.postDataForm('upload_document', this.documentInfo).then(
                async (resp) => {
                    if (resp && resp.data) {
                        this.docs.push(resp.data);
                        await nextTick();
                        document.getElementById(this.uploadSelectId).value =
                            resp.data._id;
                        document.getElementById('uploadDocument').value = '';
                        this.uploadSelectId = '';
                        this.documentInfo.desc = '';
                        this.disableUpload = false;
                        document.getElementById('closeUploadModal').click();
                    }
                }
            );
        },
        async UploadAdditionalFile() {
            UserService.postDataForm(
                'upload_additional_document/' + this.application_id,
                this.documentInfo
            ).then(async (res) => {
                console.log(res);
                console.log(this.application);
                this.updateModal({
                    type: true,
                    message: 'Document uploaded successfully',
                });
                document.getElementById('closeUploadAdditionalModal').click();
            });
        },
        calcTracker(app) {
            return calcTracker(app);
        },
        checkProfileSaved() {
            if (
                this.user?.personal_info == null ||
                this.user?.personal_info?.first_name == null ||
                this.user?.personal_info?.last_name == null
            ) {
                this.$router.push('/students/profile/personal-info');
                this.updateModal({
                    type: false,
                    message: 'Please complete your personal profile first',
                });
            }
        },
        getSchoolInfo() {
            return ApiService.getData(
                'edu/getProgram/school/' +
                    this.$route.params.school_id +
                    '/program/' +
                    this.$route.params.program_id
            ).then(
                (resp) => {
                    return Promise.resolve(resp.data);
                },
                (error) => {
                    return Promise.reject(error);
                }
            );
        },
        getApp() {
            return UserService.getData(
                'get_application/' + this.$route.params.id
            ).then(
                (resp) => {
                    return Promise.resolve(resp.data);
                },
                (error) => {
                    return Promise.reject(error);
                }
            );
        },
        getStudentProfile() {
            return UserService.getData('get_personal_info').then(
                (resp) => {
                    return Promise.resolve(resp.data);
                },
                (error) => {
                    return Promise.reject(error);
                }
            );
        },
        checkFreeAppLimit() {
            UserService.getData('get_All_Free_Pending_Applications').then(
                (resp) => {
                    if (resp && resp.data >= this.maxFreeApplications) {
                        this.updateModal({
                            type: false,
                            message: `You can only apply to ${this.maxFreeApplications} free programs at a time. Kindly wait till one of them gets processed`,
                        });
                        this.$router.go(-1);
                    }
                }
            );
        },
        startNewApp() {
            this.application_id = this.$route.params.id;
            Promise.all([
                this.getSchoolInfo(),
                this.getAllDocuments(),
                this.getStudentProfile(),
            ]).then(([sch, doc, prof]) => {
                if (sch) {
                    //check application fee

                    // combine program reqs with schools reqs
                    if(sch && sch.university && sch.university.required_docs?.length > 0){
                        sch.required_docs = [...sch.required_docs, ...sch.university.required_docs];
                        sch.required_docs.sort((a, b) => (a.isRequired > b.isRequired) ? -1 : 1);
                    }
                    this.program = sch;

                    if (this.program.application_fee == 0) {
                        this.checkFreeAppLimit();
                    }
                    this.header =
                        'Schools and Programs' +
                        '<i class="bi bi-chevron-right mx-2"></i>' +
                        this.program.university?.name +
                        '<i class="bi bi-chevron-right mx-2"></i>' +
                        this.program.name;
                }
                if (doc) {
                    this.docs = doc;
                }
                if (prof) {
                    this.user = prof;
                    this.checkProfileSaved();
                }
            });
        },
        resumeApp() {
            this.application_id = this.$route.params.id;
            Promise.all([
                this.getApp(),
                this.getAllDocuments(),
                this.getStudentProfile(),
            ]).then(([app, doc, prof]) => {
                if (app) {
                    app.program.university = app.university;
                    this.selected_session = app.academic_session;
                    this.emergency_contact_name = app.emergency_contact_name;
                    this.emergency_contact_relationship = app.emergency_contact_relationship;
                    this.emergency_contact_phone_number =
                        app.emergency_contact_phone_number;
                    this.emergency_contact_address =
                        app.emergency_contact_address;
                    this.emergency_contact_email = app.emergency_contact_email;
                    this.application = app;
                    this.isPaid =
                        this.application.is_submitted &&
                        this.application.payment?.amount_to_pay <=
                            this.application.payment?.amount_paid;
                    // combine program reqs with schools reqs
                    if(app.university && app.university.required_docs?.length > 0){
                        app.program.required_docs = [...app.program.required_docs, ...app.university.required_docs];
                        app.program.required_docs.sort((a, b) => (a.isRequired > b.isRequired) ? -1 : 1);
                    }
                         
                    this.program = app.program;
                    this.change_session();
                    this.header =
                        'Schools and Programs' +
                        '<i class="bi bi-chevron-right mx-2"></i>' +
                        this.program.university?.name +
                        '<i class="bi bi-chevron-right mx-2"></i>' +
                        this.program.name;
                    setTimeout(() => {
                        const selected =
                            document.getElementsByClassName('req_docs');
                        if (selected !== null && selected.length > 0) {
                            for (let i = 0; i < selected.length; i++) {
                                const docToUpload =
                                    selected[i].getAttribute('data-id');
                                const selectedValue =
                                    this.application.documents?.find(
                                        (x) => x.doc_to_upload === docToUpload
                                    )?.uploaded_doc;
                                selected[i].value =
                                    selectedValue == null ? '' : selectedValue;
                            }
                        }
                    }, 500);
                }
                if (doc) {
                    this.docs = doc;
                }
                if (prof) {
                    this.user = prof;
                    this.checkProfileSaved();
                }
            });
        },
        change_session() {
            if (
                this.selected_session !== '' &&
                this.program &&
                this.program.available_sessions?.length > 0
            ) {
                this.resumption_date_text =
                    this.program.available_sessions.find(
                        (x) => x.name === this.selected_session
                    ).resumption_date;
                this.application_deadline_text =
                    this.program.available_sessions.find(
                        (x) => x.name === this.selected_session
                    ).application_deadline;
            } else {
                this.resumption_date_text = 'Select Academic session first';
                this.application_deadline_text =
                    'Select Academic session first';
            }
        },
        gatherApplicationDetails() {
            let sD = {};
            // const upDocs = [];
            if (this.application !== null) {
                sD = this.application;
            }
            // emergency contact
            sD.emergency_contact_name = this.emergency_contact_name;
            sD.emergency_contact_relationship = this.emergency_contact_relationship;
            sD.emergency_contact_phone_number =
                this.emergency_contact_phone_number;
            sD.emergency_contact_address = this.emergency_contact_address;
            sD.emergency_contact_email = this.emergency_contact_email;
            sD.academic_session = this.selected_session;
            sD.academic_session = this.selected_session;
            sD.program = this.$route.params.program_id;
            sD.university = this.$route.params.school_id;
            sD.status = this.application == null ? 'pending' : sD.status;
            if (sD.documents == undefined) {
                sD.documents = [];
            }
            const selects = document.getElementsByClassName('req_docs');
            if (selects !== null && selects.length > 0) {
                for (let i = 0; i < selects.length; i++) {
                    let doc_id = selects[i].getAttribute('data-id');
                    let find = sD.documents.find(
                        (x) => x.doc_to_upload == doc_id
                    );
                    if (find) {
                        // update value
                        let select_value =
                            selects[i].value === '' ? null : selects[i].value;
                        sD.documents.find(
                            (x) => x.doc_to_upload == doc_id
                        ).uploaded_doc = select_value;
                    } else {
                        // add new value
                        const obj = {
                            doc_to_upload: selects[i].getAttribute('data-id'),
                            uploaded_doc:
                                selects[i].value === ''
                                    ? null
                                    : selects[i].value,
                            is_required: selects[i].hasAttribute('required'),
                        };
                        sD.documents.push(obj);
                    }
                }
            }
            //sD.documents = upDocs;
            return sD;
        },
        checkCanNotSubmit() {
            let deadline = this.program?.available_sessions.find(x => x.name == this.selected_session)?.application_deadline;
            let canNotSubmit = this.checkCanApply(deadline, this.program.available_sessions.length);
            return canNotSubmit;
        },
        saveApplication() {
            let canNotSubmit = this.checkCanNotSubmit();
            if(canNotSubmit) {
                this.updateModal({
                        type: false,
                        message: "The session you have chosen is now closed and you can no longer apply for it. Kindly find another session to apply for. Thanks!",
                    });
                return;
            }

            let sD = this.gatherApplicationDetails();
            sD.is_submitted = false;
            UserService.postData('create_application', sD).then((resp) => {
                if (resp && resp.data) {
                    this.updateModal({
                        type: true,
                        message: 'Application saved',
                    });
                    if (this.application_id == resp.data._id) {
                        this.application = resp.data;
                        return;
                    }
                    this.$router.push(
                        '/students/school/' +
                            this.$route.params.school_id +
                            '/program/' +
                            this.$route.params.program_id +
                            '/apply/' +
                            resp.data._id
                    );
                }
            });
        },
        submitApplyForm() {
            // check can submit 
            let canNotSubmit = this.checkCanNotSubmit();
            if(canNotSubmit) {
                this.updateModal({
                        type: false,
                        message: "The session you have chosen is now closed and you can no longer apply for it. Kindly find another session to apply for. Thanks!",
                    });
                return;
            }
            let sD = this.gatherApplicationDetails();
            sD.is_submitted = true;
            UserService.postData('create_application', sD).then((resp) => {
                if (resp && resp.data) {
                    let disMsg = 'Application saved';
                    if (
                        resp.data.payment &&
                        resp.data.payment.amount_to_pay == 0
                    ) {
                        disMsg = 'Application Submitted';
                    } else if (
                        resp.data.payment &&
                        resp.data.payment.amount_to_pay >
                            resp.data.payment.amount_paid
                    ) {
                        disMsg =
                            'Application saved, please pay application fee';
                    }
                    this.updateModal({
                        type: true,
                        message: disMsg,
                    });
                    if (this.application_id == resp.data._id) {
                        this.application = resp.data;
                        return;
                    }
                    this.$router.push(
                        '/students/school/' +
                            this.$route.params.school_id +
                            '/program/' +
                            this.$route.params.program_id +
                            '/apply/' +
                            resp.data._id
                    );
                }
            }).catch(() => {
                this.$router.push('/students/applications');
            });
        },
        RequestPaymentLink() {
            let canNotSubmit = this.checkCanNotSubmit();
            if(canNotSubmit) {
                this.updateModal({
                        type: false,
                        message: "The session you have chosen is now closed and you can no longer apply for it. Kindly find another session to apply for. Thanks!",
                    });
                return;
            }

            const text =
                "Once you pay, you can't edit the application\nPress OK to proceed to pay";
            if (!confirm(text)) {
                return;
            }
            const obj = {
                payment_id: this.application.payment?._id,
                url: window.location.href,
                program_id: this.$route.params.program_id,
            };

            UserService.postData('make_payment', obj).then((resp) => {
                if (resp && resp.data && resp.data.indexOf('checkout') > -1) {
                    window.location.href = resp.data;
                }
            });
        },
        RequestPaymentLinkPaystack() {
            
            let canNotSubmit = this.checkCanNotSubmit();
            if(canNotSubmit) {
                this.updateModal({
                        type: false,
                        message: "The session you have chosen is now closed and you can no longer apply for it. Kindly find another session to apply for. Thanks!",
                    });
                return;
            }

            const text =
                "Once you pay, you can't edit the application\nPress OK to proceed to pay";
            if (!confirm(text)) {
                return;
            }
            const obj = {
                payment_id: this.application.payment?._id,
                url: window.location.href,
                program_id: this.$route.params.program_id,
                email: this.user?.email
            };

            UserService.postData('make_payment_paystack', obj).then((resp) => {
                if(resp && resp.data == null) {
                    this.updateModal({
                        type: false,
                        message: 'An error occured',
                    });
                    return;
                }
                if (resp && resp.data && resp.data.indexOf('checkout') > -1) {
                    window.location.href = resp.data;
                }else{
                    this.updateModal({
                        type: false,
                        message: resp?.data,
                    });
                }
            });
        },
    },
    computed: {
        currentUser() {
            return this.$store.state.user.currentUser;
        },
    },
    watch: {
        $route(to) {
            if (to.params.id && to.params.id !== '0') {
                this.resumeApp();
            }
        },
    },
};
</script>
